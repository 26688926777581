import React from 'react';

const Justkitten = () => {
    return (
        <div className="bg-violet-400 overflow-hidden pt-5 h-20 w-screen">
            <h1 className="text-3xl text-center text-red-400">(JK)</h1>
        </div>
    )
}

export default Justkitten;