import React from 'react'


const Gif = () => {
    return (
        <div className="overflow-hidden w-screen flex h-auto">
            <img src="Radcat.gif" alt="Radcat" className="w-full h-auto" />
        </div>
    )
}

export default Gif;