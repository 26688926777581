import './App.css';
import Gif from './components/Gif';
import Justkitten from './components/Justkitten';
import Links from './components/Links';
// import Header from './components/Header';
// import Footer from './components/Footer';

function App() {

  return (
    <div className="App">
      <div className="container">

        <Gif />
        <Justkitten />
        <Links />
      </div>
    </div>
  )
}


export default App;
